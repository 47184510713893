import React from 'react';
import logoWhite from '../images/logo_mcj_white.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faTelegram, faDiscord } from '@fortawesome/free-brands-svg-icons'
import { motion } from 'framer-motion';

let easing = [0.1575, 0.85, 0.42, 0.96];

const slideUpAnimation = {
  enter: { y: 0, opacity: 1, transition: { duration: 1.3, ease: easing } },
  exit: { y: 50, opacity: 0, transition: { duration: 1.3, ease: easing } }
}

const Footer = props => {
  const [lastYPos, setLastYPos] = React.useState(0);

  const [shouldShowSection, setShouldShowSection] = React.useState(false);

  React.useEffect(() => {
    function handleScroll() {
      const yPos = window.scrollY;

      let shouldShowSection;

      let sectionPos = document.querySelector("#footer").getBoundingClientRect();

      if (sectionPos.top < 600) {
        shouldShowSection = true;
      }

      if (sectionPos.top < -100) {
        shouldShowSection = false;
      }

      setShouldShowSection(shouldShowSection);
      setLastYPos(yPos);
    }

    window.addEventListener("scroll", handleScroll, false);

    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, [lastYPos]);
  return (
    <div id="footer" className="container-inner footer">
      <div className="logo-wrapper">
        <img width="290" src={logoWhite}/>
        <div>
          MyCryptoJournal is a platform that connects to your favorite 
          exchange and empowers you to import, track and analyze your trades. The easy way.
        </div>
      </div>
      <div className="socials">
        <a target="_blank" href="https://twitter.com/MyCryptoJournaI">
          <FontAwesomeIcon className="icon" icon={faTwitter}/>
        </a>

        <a target="_blank" href="https://t.me/Mycryptojournal">
          <FontAwesomeIcon className="icon" icon={faTelegram}/>
        </a>

        <a target="_blank" href="https://discordapp.com/channels/521615222994370560/521615222994370562">
          <FontAwesomeIcon className="icon" icon={faDiscord}/>
        </a>
      </div>
    </div>
  )
}

export default Footer;