import React, { useState } from 'react';
import Link from 'gatsby-link';
import logo from '../images/logo_mcj.svg';
import logoWhite from '../images/logo_mcj_white.svg';
import menu from '../images/menu.svg';
import close from '../images/close.svg';
import { motion } from 'framer-motion';
import top from '../images/top.svg';
import AnchorLink from 'react-anchor-link-smooth-scroll'

let easing = [0.1575, 0.85, 0.42, 0.96];

const slideDownAnimation = {
  enter: { y: 0, opacity: 1, transition: { duration: 1.3, ease: easing } },
  exit: { y: -50, opacity: 0, transition: { duration: 1.3, ease: easing } }
}

const zoomInAnimation = {
  enter: { y: 0, opacity: 1, transition: { duration: 1, ease: easing } },
  exit: { y: 0, opacity: 0, transition: { duration: 1, ease: easing } }
}

const Navigation = (props) => {
  const [lastYPos, setLastYPos] = React.useState(0);

  const [shouldShowSection, setShouldShowSection] = React.useState(false);
  const [shouldShowBackToTop, setShouldShowBackToTop] = React.useState(false);
  const [navigationClosed, setNavigationClosed] = useState(true);

  React.useEffect(() => {
    function handleScroll() {
      const yPos = window.scrollY;

      let shouldShowSection;
      let shouldShowBackToTop;

      if ((window.innerHeight + window.scrollY) > 1000) {
        shouldShowBackToTop = true;
      }

      let sectionPos = document.querySelector("#navigation").getBoundingClientRect();

      if (sectionPos.top < 5) {
        shouldShowSection = true;
      }

      setShouldShowSection(shouldShowSection);
      setShouldShowBackToTop(shouldShowBackToTop);
      setLastYPos(yPos);
    }

    window.addEventListener("scroll", handleScroll, false);

    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, [lastYPos]);

  return (
    <motion.div initial="exit" animate="enter" exit="exit" id="navigation" className="navigation">
      <motion.div variants={slideDownAnimation} className={`nav-logo-wrapper container-inner ${navigationClosed ? 'closed' : 'open'}`}>
        {navigationClosed &&
        <motion.div initial={slideDownAnimation.enter} animate={shouldShowSection ? slideDownAnimation.exit : slideDownAnimation.enter} className="logo">
          <img width="200px" src={logo}/>
        </motion.div>
        }

        {!navigationClosed &&
        <motion.div initial={slideDownAnimation.enter} animate={shouldShowSection ? slideDownAnimation.exit : slideDownAnimation.enter} className="logo logo-white">
          <img width="200px" src={logoWhite}/>
        </motion.div>
        }

        {navigationClosed &&  
          <motion.div initial={slideDownAnimation.enter} animate={shouldShowSection ? slideDownAnimation.exit : slideDownAnimation.enter} 
            onClick={() => setNavigationClosed(!navigationClosed)} 
            className="navigation-btn">
              <img width="20px" src={menu}></img>
          </motion.div>
        }

        {!navigationClosed && 
          <motion.div initial={slideDownAnimation.enter} animate={shouldShowSection ? slideDownAnimation.exit : slideDownAnimation.enter} 
            onClick={() => setNavigationClosed(!navigationClosed)} 
            className="navigation-btn close">
              <img width="20px" src={close}></img>
          </motion.div>
        }
      </motion.div>

      <motion.div variants={slideDownAnimation} id="home" className="navigation--menu container-inner">
        <motion.ul initial={slideDownAnimation.enter} animate={shouldShowSection ? slideDownAnimation.exit : slideDownAnimation.enter}>
          <AnchorLink offset="50" href='#home'>
            <li>
              <div className="outer"></div>
              Home
            </li>
          </AnchorLink>

          <AnchorLink offset="50" href='#features'>
            <li>
              <div className="outer"></div>
              Features
            </li>
          </AnchorLink>

          <AnchorLink offset="50" href='#faq'>
            <li>
              <div className="outer"></div>
              Faq
            </li>
          </AnchorLink>

          <a href='https://app.mycryptojournal.io/login'>
            <li>
              <div className="outer"></div>
              Login
            </li>
          </a>
        </motion.ul>
      </motion.div>
    
      <motion.ul initial={slideDownAnimation.enter} animate={shouldShowSection ? slideDownAnimation.exit : slideDownAnimation.enter} className={`navigation--mobile ${navigationClosed ? "closed" : ""}`}>
        <AnchorLink offset="50" href='#home' onClick={() => setNavigationClosed(!navigationClosed)}>
          <li>
            <div className="outer"></div>
            Home
          </li>
        </AnchorLink>

        <AnchorLink offset="50" href='#features' onClick={() => setNavigationClosed(!navigationClosed)}>
          <li>
            <div className="outer"></div>
            Features
          </li>
        </AnchorLink>

        <AnchorLink offset="50" href='#faq' onClick={() => setNavigationClosed(!navigationClosed)}>
          <li>
            <div className="outer"></div>
            Faq
          </li>
        </AnchorLink>

        <a href='https://app.mycryptojournal.io/login'>
          <li>
            <div className="outer"></div>
            Login
          </li>
        </a>
      </motion.ul>
      <AnchorLink offset="50" href='#navigation'>
        <motion.div variants={zoomInAnimation}>
          <motion.img className="back-to-top" src={top} initial={zoomInAnimation.exit} animate={shouldShowBackToTop ? zoomInAnimation.enter : zoomInAnimation.exit}></motion.img>
        </motion.div>
      </AnchorLink>
    </motion.div>
  )
}

export default Navigation;