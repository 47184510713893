import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Navigation from "./Navigation"
import Footer from "./Footer"
import footerCurve from '../images/footer_curve.svg'

import '../assets/styles/index.css'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <section className="section section--navigation">
        <Navigation/>
      </section>

      <div>
        {children}
      </div>

      <div className="footer-svg">
        <img src={footerCurve}/>
      </div>
      <section className="section section--footer">
        <Footer/>
     </section>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
